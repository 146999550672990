import axios, { AxiosResponse } from 'axios';

const instance = axios.create({
  timeout: 15000,
  headers: { 'Content-Type': 'application/json' },
});

const responseBody = <T = unknown>(response: AxiosResponse) => response.data;

export const get = <T, U = unknown>(url: string): Promise<U> =>
  instance.get<T>(url).then(responseBody);

export const post = <T, U = unknown>(url: string, body: {}): Promise<U> =>
  instance.post<T>(url, body).then(responseBody);

export const put = <T, U = unknown>(url: string, body: {}): Promise<U> =>
  instance.put<T>(url, body).then(responseBody);

export const destroy = <T, U = unknown>(url: string): Promise<U> =>
  instance.delete<T>(url).then(responseBody);
