import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select'
import { get } from '../utils/requests/base';
import { FixedSizeList as List } from 'react-window';
import * as $ from 'jquery';

interface JslnIngredient {
  id: number;
  name: string;
}

interface Option {
  value: number;
  label: string;
}

interface Props {
  options: Option[];
}

const MenuList = (props: any) => {
  const height = 35;
  const { options, children, maxHeight, getValue }: any = props;
  const [value] = getValue();
  const initialOffset = options.indexOf(value) * height;

  return (
    <List
      height={maxHeight}
      width={700}
      itemCount={children.length}
      itemSize={height}
      initialScrollOffset={initialOffset}
    >
      {({ index, style }) => <div style={style}>{children[index]}</div>}
    </List>
  );
}

const jslnIngredientToOption = (jslnIngredients: JslnIngredient[]): Option[] =>
  jslnIngredients.map((j: JslnIngredient) => ({ value: j.id, label: j.name } as Option));

const App: React.FC<Props> = (props) => {
  const [selectedItem, setSelectedItem] = useState<Option[]>(props.options as Option[]);
  const [options, setOptions] = useState<Option[]>([] as Option[]);
  const [isLoading, setisLoading] = useState<boolean>(true);

  useEffect(() => {
    let unmounted = false;
    const f = async () => {
      const limit: number = 1000;
      let responseSize: number = limit;
      let offset: number = 0;
      let responseData: any[] = [];

      // 1.5万件を全てloadするとtimeoutするので小分けでload
      while (responseSize >= limit) {
        const response: any[] = await get(`/api/jsln_ingredients?limit=${limit}&offset=${offset}`);
        responseData = responseData.concat(response);
        responseSize = response.length;
        offset += response.length;
      };

      setOptions( jslnIngredientToOption(responseData as JslnIngredient[]));
      setisLoading(false);
    };
    f();
    return () => { unmounted = true; };
  }, []);

  const handleChange = (option: Option[]): void => setSelectedItem(option);

  return (
    <div className="z-50 mb-40">
      {isLoading
        ? <div>化粧品表示名称をロード中です........</div>
        : (
          <Select
            components={{MenuList}}
            options={options}
            isMulti
            defaultValue={props.options}
            onChange={handleChange}
          />
        )
      }
      {
        selectedItem.length >= 1
          ? selectedItem.map(item => <input type="hidden" name="product[jsln_ingredient_ids][]" value={item.value} />)
          : <input type="hidden" name="product[jsln_ingredient_ids][]" />
      }
    </div>
  );
};

document.addEventListener('turbolinks:load', () => {
  $(() => {
    const elm = document.querySelector('#jslnIngredientsSelect');
    if (elm === null) {
        return;
     }
     const jslnIngredients = JSON.parse(elm.getAttribute('jslnIngredients'));
     if (elm) {
       ReactDOM.render(
         <App options={jslnIngredients.map(jslnIngredient => ({ value: jslnIngredient.id, label: jslnIngredient.name } as Option))} />,
         elm,
       );
     }
  });
});
